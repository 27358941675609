import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignupForm = ({ onGetStarted }) => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for the error message
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  const handleGetStartedClick = async () => {
    const regex =
      /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|9715\d{8})$/;
    const isValid = regex.test(emailOrPhone);

    if (!isValid) {
      toast.error("Invalid email or phone number"); // Show toast notification
      return;
    }

    const isEmail = emailOrPhone.includes("@");
    const isPhoneNumber = /^[\d\s+()\-]+$/.test(emailOrPhone);

    if (isEmail) {
      sessionStorage.setItem("userEmail", emailOrPhone);
      sessionStorage.removeItem("userPhoneNumber");
    } else if (isPhoneNumber) {
      sessionStorage.setItem("userPhoneNumber", emailOrPhone);
      sessionStorage.removeItem("userEmail");
    } else {
      toast.error("Invalid email or phone number"); // Show toast notification
      return;
    }

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:7002/auth-service/auth/send-otp`,
      params: { email: emailOrPhone },
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      // console.log('OTP sent:', data);

      if (data.statusCode === 400) {
        setErrorMessage("Failed to send OTP. Try with email"); // Set the error message
        return; // Stop further execution
      }

      setErrorMessage(""); // Clear the error message on success
      onGetStarted(emailOrPhone);
    } catch (error) {
      console.error("Failed to send OTP:", error);
      toast.error("Failed to send OTP"); // Show toast notification
    }
  };

  return (
    <div className="flex flex-col items-start sm:p-4 gap-4  lg:mr-5 mx-auto lg:mb-32 xl:mt-[-15px] ">
      <ToastContainer />
      <button
        onClick={handleClick}
        className="hidden w-28 h-9 px-4 mb-2 py-2 sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
      >
        <span className="">
          <MdKeyboardArrowLeft />
        </span>
        <span className="font-bold text-[14px] leading-[20px]">Go Back</span>
      </button>
      <div className="flex flex-col items-start max-w-[400px]">
        <h2 className="text-[32px] lg:text-4xl font-bold font-plus-jakarta-sans text-[#101828] mb-3">
          Get Repair Estimation
        </h2>
        <p className="text-[14px] lg:text-[16.7px] text-[#475467] font-plus-jakarta-sans mb-4">
          By entering your email, we'll verify and show the estimated price of
          the repair.
        </p>
      </div>
      <div className="w-full lg:w-[400px] max-w-full flex flex-col items-start gap-4 p-6 bg-white border border-gray-300 rounded-lg">
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label className=" font-plus-jakarta-sans text-[#344054]">
              Email
            </label>
            <input
              type="text"
              placeholder="Enter your email"
              value={emailOrPhone}
              onChange={(e) => {
                setEmailOrPhone(e.target.value);
                setErrorMessage(""); // Clear the error message when the input changes
              }}
              className="w-full h-11 p-2 rounded-md border border-gray-300 font-plus-jakarta-sans placeholder-[#667085] text-lg"
            />
            {errorMessage && (
              <span className="text-red-500 text-sm mt-1">{errorMessage}</span> // Display the error message
            )}
          </div>
        </div>
        <button
          onClick={handleGetStartedClick}
          className="w-full h-11 flex items-center justify-center bg-yellow-400 text-black font-bold rounded-md mt-4 font-plus-jakarta-sans"
        >
          Get Started
        </button>
      </div>
      <p className="font-plus-jakarta-sans text-base text-[#475467]">
        Don't worry, we won't spam you.
      </p>
    </div>
  );
};

export default SignupForm;
