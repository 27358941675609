import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usercheck from "./styles/users-check.png";
import building from "./styles/building-02.png";
import checkverified from "./styles/check-verified-01.png";
import Category from "./Category";
import Navbar from "../Navbar";
import Loader from "../../components/loader/Loader";

const HeaderRepair = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}:8070/master-service/categories`
        );
        const data = response.data;
        // console.log(data);
        if (data && Array.isArray(data.primaryEntitys)) {
          setCards(data.primaryEntitys);
        } else {
          throw new Error("Response does not contain primaryEntitys array");
        }
      } catch (error) {
        setError(error.message);
        toast.error("Failed to fetch categories.");
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // if (loading) return <Loader loading={loading} />;

  return (
    <div className="bg-Yellow ">
      <Navbar />
      <div className="rounded-b-gr rounded-lb-gr flex flex-col gap-25 font-grFont leading-[44px] pb-[60px]">
        <Loader loading={loading} />

        <ToastContainer />
        <h1 className="sm:text-[41px] text-[36px] font-bold self-center text-center sm:leading-[60px] leading-[44px] tracking-[-2%] lg:mt-12">
          {location.pathname !== "/care" && (
            <>
              <span className="block font-plus-jakarta-sans md:inline">
                Repair Your{" "}
              </span>
              <span className="block font-plus-jakarta-sans md:inline">
                gadgets with ease.
              </span>
            </>
          )}
          {location.pathname === "/care" && (
            <span>We have all your devices covered.</span>
          )}
        </h1>
        <div className="flex self-center gap-[14px] lg:gap-12 px-6 flex-wrap font-DMSans font-[400] leading-[28px]">
          <div className="flex flex-row gap-[4px] flex-1 justify-center lg:flex-auto whitespace-nowrap">
            <img
              src={usercheck}
              className="h-[20px] w-[20px] self-center"
              alt="usercheck"
            ></img>
            <p className="text-base font-plus-jakarta-sans">
              Trained Professionals
            </p>
          </div>
          <div className="flex flex-row gap-[4px] flex-1 justify-center lg:flex-auto whitespace-nowrap">
            <img
              src={building}
              className="h-[20px] w-[20px] self-center"
              alt="building"
            ></img>
            <p className="text-base font-plus-jakarta-sans">Doorstep Service</p>
          </div>
          <div className="flex flex-row gap-[4px] w-full justify-center lg:w-auto whitespace-nowrap">
            <img
              src={checkverified}
              className="h-[20px] w-[20px] self-center"
              alt="checkverified"
            ></img>
            <p className="text-base font-plus-jakarta-sans">
              Guaranteed Safety
            </p>
          </div>
        </div>
        <div className="sm:self-center flex h-full flex-col mt-[34px]">
          <p className="self-center font-[700] font-plus-jakarta-sans sm:text-2xl text-[20px] sm:mb-0 mb-[20px]">
            {location.pathname !== "/care" && <>Choose What to Repair</>}
            {location.pathname === "/care" && <>Choose What To Care</>}
          </p>
          <div className="grid sm:grid-cols-4 sm:gap-[80px] gap-[30px] grid-cols-2 mt-6 lg:mt-5 xl:mt-[120px] sm:px-0 px-4">
            {cards.map((ele, index) => (
              <Category
                key={index}
                name={ele.name}
                icon={ele.imageUrl}
                skuCode={ele.skuCode}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderRepair;
