import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Table, message, Pagination } from "antd";
import axios from "axios";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

function BookingHistory() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0); // Dynamic page number
  const [pageSize, setPageSize] = useState(15); // Dynamic page size
  const [totalItems, setTotalItems] = useState(0); // Total number of items

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookingData = async () => {
      setLoading(true);
      try {
        const token = sessionStorage.getItem("authToken");
        if (!token) {
          message.error("Authentication token is missing.");
          return;
        }

        const response = await axios.get(
          `https://apiv1.gadgetsreborn.com:8899/booking-service/service-request/all-booking-of-user`,
          {
            params: { pageNo, pageSize },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 && response.data.status === 200) {
          const bookings = response.data.data.content.map((booking, index) => ({
            key: index + 1,
            sno: index + 1,
            bookingId: booking.serviceUuid,
            date: new Date(booking.createdAt).toLocaleDateString(),
            category: booking.categoryName?.trim() || "N/A",
            brand: booking.brandName?.trim() || "N/A",
            model: booking.modelName?.trim() || "N/A",
            status: booking.userStatus?.replace("_", " ") || "N/A",
            amount: booking.totalAmount,
          }));
          setDataSource(bookings);
          setTotalItems(response.data.data.totalItems); // Set total items for pagination
        } else {
          message.error("Failed to fetch booking data.");
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
        message.error("An error occurred while fetching booking data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBookingData();
  }, [pageNo, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setPageNo(page - 1); // API expects 0-based page number
    setPageSize(pageSize);
  };

  const handleRowClick = (record) => {
    navigate(`/repair-history/${record.bookingId}`);
  };

  const columns = [
    {
      title: "S/NO",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (text) => <Link to={`/repair-history/${text}`}>{text}</Link>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      responsive: ["md"],
    },
    {
      title: "",
      key: "arrow",
      render: (text, record) => (
        <Link to={`/repair-history/${record.bookingId}`}>
          <MdKeyboardArrowRight size={20} />
        </Link>
      ),
    },
  ];

  return (
    <div className="sm:mx-[40px]">
      <Navbar />
      <div className="min-h-screen sm:mx-0 mx-4 sm:block hidden">
        <div className="text-2xl font-bold my-[32px] ml-4 sm:ml-[58px]">
          Repair History
        </div>
        <div className="border rounded-[24px] shadow py-3 px-2 sm:px-6 mx-0 sm:mx-[60px]">
          <div className="overflow-x-auto">
            <Table
              dataSource={dataSource}
              columns={columns}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
              pagination={{
                current: pageNo + 1,
                pageSize,
                total: totalItems,
                showSizeChanger: false, // Hide the page size changer dropdown
                onChange: (page) => {
                  setPageNo(page - 1); // Ant Design's pagination is 1-based, but the API is 0-based
                },
              }}
              loading={loading}
              scroll={{ x: 800 }}
            />
          </div>
        </div>
      </div>

      {/* Mapping the card content from the API data */}
      <div className="text-xl md:text-2xl font-bold sm:hidden block my-4 ml-6 md:ml-8 lg:ml-16">
        Repair History
      </div>

      {/* Phone version */}
      {dataSource.map((booking) => (
        <Link to={`/repair-history/${booking.bookingId}`} key={booking.key}>
          <div className="bg-white block sm:hidden p-4 rounded-md shadow mt-4 border cursor-pointer mx-[24px]">
            <div>
              <h3 className="text-[#101828] text-[18px] font-bold">
                {booking.model}
              </h3>
            </div>
            <div className="flex justify-between items-center border-b-2 pb-2">
              <p className="text-[#475467] font-medium">{booking.bookingId}</p>
              <p className="text-[#475467] font-medium">{booking.date}</p>
            </div>
            <div className="flex justify-between items-center py-2">
              <p className="text-[#101828] text-[18px] font-semibold">
                {booking.brand}
              </p>
              <p className="text-[#101828] text-[18px] font-semibold">
                {" "}
                AED {booking.amount}{" "}
              </p>
            </div>
            <div className="flex justify-between items-center">
              <span
                className={`bg-[#FBC903] font-semibold text-sm text-[#101828] px-4 py-1 rounded-[6px]`}
              >
                {booking.status}
              </span>
            </div>
          </div>
        </Link>
      ))}
      <div className="sm:hidden block mx-[24px]">
        <Pagination
          current={pageNo + 1}
          pageSize={pageSize}
          total={totalItems} // Set total items for pagination
          onChange={handlePageChange}
          showSizeChanger={false} // Hide the "page size" selector
          className="my-4" // Optional: Add margin to separate pagination from content
        />
      </div>
      <Footer />
    </div>
  );
}

export default BookingHistory;
