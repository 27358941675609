import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import tick from '../../assets/Tick.webp';

const steps = [
  { id: 1, label: 'Select Issue', paths: ['/repair/defects'] },
  { id: 2, label: 'Select Device', paths: ['/repair/category', '/repair/sign-up'] },
  { id: 3, label: 'Get Estimation', paths: ['/repair/booking-fee'] },
  { id: 4, label: 'Select Address', paths: ['/address'] },
  { id: 5, label: 'Confirm Booking', paths: ['/booking-confirmedconfirm'] }
];

const MobileNav = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeStep = steps.find(step => step.paths.includes(currentPath));
    if (activeStep) {
      setCurrentStep(activeStep.id);
    }
  }, [location.pathname]);

  return (
    <div className="flex items-center">
      {steps.map((step, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`w-9 h-9 rounded-full flex items-center justify-center ${
              currentStep >= step.id ? 'bg-[#E4b703] text-white' : 'bg-white border-2 border-gray-300'
            }`}
          >
            {currentStep > step.id ? (
              <img src={tick} alt="Tick" className="w-4 h-4" />
            ) : currentStep === step.id ? (
              <div className="w-3 h-3 bg-white rounded-full"></div>
            ) : (
              <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
            )}
          </div>
          {index !== steps.length - 1 && (
            <div
              className={`w-10 h-[2px] ${
                currentStep >= step.id ? 'bg-[#E4b703]' : 'bg-gray-200'
              }`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileNav;
