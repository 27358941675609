import React, { useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
const BookingFee = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { priceData = {} } = location.state || {};
  const defectName = sessionStorage.getItem("defectName");

  const [isOriginalCardCollapsed, setIsOriginalCardCollapsed] = useState(false);
  const [isAfterMarketCardCollapsed, setIsAfterMarketCardCollapsed] =
    useState(false);

  const fetchServiceUuid = async (estimatedPrice) => {
    const token = sessionStorage.getItem("authToken");
    const defectName = sessionStorage.getItem("defectName");
    const brandSkuCode = sessionStorage.getItem("brandSkuCode");
    const categoryName = sessionStorage.getItem("categoryName");
    const defectSkuCode = sessionStorage.getItem("defectSkuCode");
    const modelSkuCode = sessionStorage.getItem("modelSkuCode");
    const categorySkuCode = sessionStorage.getItem("categorySkuCode");
    const modelName = sessionStorage.getItem("modelName");
    const brandName = sessionStorage.getItem("brandName");

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/service-request/add`,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "*/*",
      },
      data: {
        categorySku: categorySkuCode,
        categoryName: categoryName,
        brandSku: brandSkuCode,
        brandName: brandName,
        modelSku: modelSkuCode,
        modelName: modelName,
        totalAmount: estimatedPrice,
        defectName: defectName,
        defectSku: defectSkuCode,
        couponCode: "",
      },
    };

    try {
      const { data } = await axios.request(options);
      console.log("ServiceId", data);
      sessionStorage.setItem("serviceUuid", data.serviceUuid);
      console.log("ServiceId", data.serviceUuid);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    navigate("/repair/category", { state: { ...location.state } });
  };

  const handleContinue = async (priceType, price) => {
    // await fetchServiceUuid(price);
    sessionStorage.setItem("selectedPrice", price); // Store the selected price in session storage
    navigate("/repair/address", {
      state: { ...location.state, estimatedPrice: price, priceType },
    });
  };
  const toggleOriginalCard = () => {
    setIsOriginalCardCollapsed(!isOriginalCardCollapsed);
  };

  const toggleAfterMarketCard = () => {
    setIsAfterMarketCardCollapsed(!isAfterMarketCardCollapsed);
  };

  const isDiagnosticOnly =
    !priceData.originalMaxPrice && !priceData.afterSaleMaxPrice;
  const isSinglePrice =
    !priceData.originalMaxPrice || !priceData.afterSaleMaxPrice;
  const singlePrice = priceData.originalMaxPrice || priceData.afterSaleMaxPrice;
  const singlePriceType = priceData.originalMaxPrice ? "original" : "afterSale";

  return (
    <div className="sm:gap-8 opacity-100 flex flex-col items-start lg:mr-20">
      <div className="sm:w-[465px] h-[128px] gap-4 sm:flex flex-col">
        <button
          onClick={handleGoBack}
          className="hidden w-28 h-9 px-4 mb-3 py-2 sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
        >
          <span className="">
            <MdKeyboardArrowLeft />
          </span>
          <span className="font-bold font-plus-jakarta-sans text-[14px] leading-[20px]">
            Go Back
          </span>
        </button>
        <div className="">
          <h2 className="sm:text-[36px] text-[28px] leading-[44px] font-plus-jakarta-sans font-bold text-left text-[#101828]">
            Pay a Booking Fee
          </h2>
          <p className="text-[16px] leading-[24px] font-normal font-plus-jakarta-sans sm:whitespace-nowrap text-left text-[#475467] mt-2">
            The estimated price may vary post physical inspection &
            troubleshooting of the device.
          </p>
        </div>
      </div>

      {isDiagnosticOnly ? (
        <div
          className={`h-auto px-8 py-6 bg-white rounded-2xl shadow-md mt-4 border border-yellow-400 sm:min-w-[580px] w-96 cursor-pointer`}
        >
          <div className="w-full h-auto grid sm:grid-cols-2 grid-cols-1 sm:gap-[200px] items-start">
            <div className="sm:w-[353px] h-auto flex flex-col">
              <div className="w-auto h-auto flex items-center gap-4">
                <h3 className="text-2xl font-semibold font-plus-jakarta-sans text-[#101828]">
                  Diagnostic Fee
                </h3>
                <span className="px-2 py-1 text-sm font-medium text-yellow-700 bg-yellow-100 rounded-lg">
                  Best Price
                </span>
              </div>
            </div>
            <div className="text-right flex gap-1 lg:mt-1 mt-4 pb-5">
              <span className="block text-2xl font-medium text-[#101828]">
                AED
              </span>
              <span className="block text-3xl lg:text-5xl font-bold text-[#101828]">
                {Math.round(priceData.diagnosticFees)}
              </span>
            </div>
          </div>
          <p className="lg:mt-[-15px] text-md font-normal font-plus-jakarta-sans text-[#475467]">
            The diagnostic fee is just an initial charge. The final prices will
            be provided after the diagnosis.
          </p>
          <hr className="my-4 border-t border-gray-200" />
          <button
            onClick={() =>
              handleContinue("diagnostic", priceData.diagnosticFees)
            }
            className="text-black w-full h-12 flex items-center justify-center gap-2 bg-Yellow text-lg font-medium rounded-md shadow-sm"
          >
            Continue to Pay
          </button>
        </div>
      ) : isSinglePrice ? (
        <div>
          <div className="flex justify-between px-6 py-6">
            <div>
              <h3 className="text-xl whitespace-nowrap font-semibold font-plus-jakarta-sans text-[#101828]  max-w-[200px]">
                Estimated Repair Fee
              </h3>
              {/* <p className="whitespace-nowrap">Estimated repair time</p> */}
            </div>

            <div className="text-right flex col-span-1 gap-1 lg:mt-1 mt-4">
              <span className="block text-2xl font-medium text-[#101828]">
                AED
              </span>
              <span className="block text-3xl lg:text-5xl font-semibold text-[#101828]">
                {Math.round(singlePrice)}
              </span>
            </div>
          </div>
          <div
            className={`h-auto px-6 py-6 bg-white rounded-2xl shadow-md mt-4 border border-yellow-400 sm:w-[580px] w-full cursor-pointer`}
          >
            <div className="w-full h-auto grid sm:grid-cols-1 grid-cols-1 items-start justify-between">
              <div className="flex justify-between ">
                <div className="">
                  <h3 className="text-xl font-semibold font-plus-jakarta-sans text-[#101828]">
                    Booking Fee
                  </h3>
                  <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                    This fee is non-refundable and will be deducted <br /> from
                    the final payable fee.
                  </p>
                </div>

                <div className="text-right flex gap-1 lg:mt-1 mt-4">
                  <span className="block text-2xl font-medium text-[#101828]">
                    AED
                  </span>
                  <span className="block text-3xl lg:text-5xl font-bold text-[#101828]">
                    30
                  </span>
                </div>
              </div>
            </div>
            {(singlePriceType === "original" &&
              priceData.estimatedDurationforOriginal > 0) ||
            (singlePriceType === "afterSale" &&
              priceData.estimatedDurationForAfterMarket > 0) ? (
              <p className="lg:mt-[-15px] text-md font-normal font-plus-jakarta-sans text-[#475467]">
                Estimated repair duration:{" "}
                {singlePriceType === "original"
                  ? priceData.estimatedDurationforOriginal
                  : priceData.estimatedDurationForAfterMarket}
              </p>
            ) : null}
            {singlePriceType === "original" &&
              priceData.originalWarrenty > 0 && (
                <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                  {priceData.originalWarrenty} Days warranty
                </p>
              )}
            {singlePriceType === "afterSale" &&
              priceData.afterSaleWarrenty > 0 && (
                <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                  {priceData.afterSaleWarrenty} Days warranty
                </p>
              )}
            <hr className="my-4 border-t border-gray-200" />
            <button
              onClick={() => handleContinue(singlePriceType, singlePrice)}
              className="text-black w-full h-12 flex items-center justify-center gap-2 bg-Yellow text-lg font-medium rounded-md shadow-sm"
            >
              Continue to Pay
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`h-auto px-8 py-6 bg-white rounded-2xl shadow-md mt-4 border ${
              isOriginalCardCollapsed ? "border-yellow-400" : "border-[#EAECF0]"
            } sm:min-w-[580px] w-96 cursor-pointer`}
            onClick={toggleOriginalCard}
          >
            <div className="w-full h-auto grid sm:grid-cols-2 grid-cols-1 sm:gap-[200px] items-start">
              <div className="sm:w-[353px] h-auto flex flex-col">
                <div className="w-auto h-auto flex items-center gap-4">
                  <h3 className="text-2xl font-semibold font-plus-jakarta-sans text-[#101828]">
                    Original Price
                  </h3>
                  <span className="px-2 py-1 text-sm font-medium text-yellow-700 bg-yellow-100 rounded-lg">
                    Best Price
                  </span>
                </div>
              </div>
              <div className="text-right flex gap-1 lg:mt-1 mt-4">
                <span className="block text-2xl font-medium text-[#101828]">
                  AED
                </span>
                <span className="block text-3xl lg:text-5xl font-bold text-[#101828]">
                  {Math.round(priceData.originalMaxPrice)}
                </span>
              </div>
            </div>
            <p className="lg:mt-[-15px] text-md font-normal font-plus-jakarta-sans text-[#475467]">
              Estimated repair duration:{" "}
              {priceData.estimatedDurationforOriginal}
            </p>
            {priceData.originalWarrenty > 0 && (
              <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                {priceData.originalWarrenty} Days warranty
              </p>
            )}
            {isOriginalCardCollapsed && (
              <>
                <hr className="my-4 border-t border-gray-200" />
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-2xl font-semibold font-plus-jakarta-sans text-[#101828]">
                      Booking fee
                    </h3>
                    <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                      This fee is non-refundable and will be deducted <br />{" "}
                      from the final payable fee.
                    </p>
                  </div>
                  <div className="text-right flex gap-1 lg:mt-1 mt-4">
                    <span className="block text-2xl font-medium text-[#101828]">
                      AED
                    </span>
                    <span className="block text-3xl lg:text-5xl font-bold text-[#101828]">
                      30
                    </span>
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleContinue("original", priceData.originalMaxPrice)
                  }
                  className="w-full h-12 mt-3 flex items-center justify-center gap-2 bg-Yellow text-lg font-medium text-black rounded-md shadow-sm"
                >
                  Continue to Pay
                </button>
              </>
            )}
          </div>
          <div
            className={`h-auto px-8 py-6 bg-white rounded-2xl shadow-md mt-4 border ${
              isAfterMarketCardCollapsed
                ? "border-yellow-400"
                : "border-[#EAECF0]"
            } sm:min-w-[580px] w-96 cursor-pointer`}
            onClick={toggleAfterMarketCard}
          >
            <div className="w-full h-auto grid sm:grid-cols-2 grid-cols-1 sm:gap-[200px] items-start">
              <div className="sm:w-[353px] h-auto flex flex-col">
                <div className="w-auto h-auto flex items-center gap-4">
                  <h3 className="text-2xl font-semibold font-plus-jakarta-sans text-[#101828]">
                    After-market price
                  </h3>
                  <span className="px-2 py-1 text-sm font-medium text-yellow-700 bg-yellow-100 rounded-lg">
                    Best Price
                  </span>
                </div>
              </div>
              <div className="text-right flex gap-1 lg:mt-1 mt-4">
                <span className="block text-2xl font-medium text-[#101828]">
                  AED
                </span>
                <span className="block text-3xl lg:text-5xl font-bold text-[#101828]">
                  {Math.round(priceData.afterSaleMaxPrice)}
                </span>
              </div>
            </div>
            <p className="lg:mt-[-15px] text-md font-normal font-plus-jakarta-sans text-[#475467]">
              Estimated repair duration:{" "}
              {priceData.estimatedDurationForAfterMarket}
            </p>
            {priceData.afterSaleWarrenty > 0 && (
              <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                {priceData.afterSaleWarrenty} Days warranty
              </p>
            )}
            {isAfterMarketCardCollapsed && (
              <>
                <hr className="my-4 border-t border-gray-200" />
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-2xl font-semibold font-plus-jakarta-sans text-[#101828]">
                      Booking fee
                    </h3>
                    <p className="text-md font-normal font-plus-jakarta-sans text-[#475467]">
                      This fee is non-refundable and will be deducted <br />{" "}
                      from the final payable fee.
                    </p>
                  </div>
                  <div className="text-right flex gap-1 lg:mt-1 mt-4">
                    <span className="block text-2xl font-medium text-[#101828]">
                      AED
                    </span>
                    <span className="block text-3xl lg:text-5xl font-bold text-[#101828]">
                      30
                    </span>
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleContinue("afterSale", priceData.afterSaleMaxPrice)
                  }
                  className="w-full h-12 mt-3 flex items-center justify-center gap-2 bg-Yellow text-lg font-medium text-black rounded-md shadow-sm"
                >
                  Continue to Pay
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BookingFee;
