import React from 'react'
import icon1 from './styles/Icon.png'
import icon2 from './styles/Icon(1).png'
import icon3 from './styles/Icon(2).png'
const FeaturesRepair = () => {
  return (
    <div className='bg-grSilver py-[64px] font-grFont font-[400] rounded-gr flex items-center justify-center mt-12 sm:mx-0 mx-[20px]'>
    <div className='flex flex-col gap-[60px] lg:gap-[150px] lg:flex-row self-center ml-[-16px]'>
    <div className='flex flex-col gap-[10px]'>
      <img src={icon1} className='h-[40px] w-[40px] mb-4'></img>
      <h2 className='font-[700] font-plus-jakarta-sans text-[24px] leading-[32px] '>Check Price</h2>
      <p className='text-[18px] font-plus-jakarta-sans leading-[28px] font-[400]' >Select your gadget that needs to <br></br>
      be repaired.Get best Pricing.</p>
    </div>
    <div className=' flex flex-col gap-[10px]'>
      <img src={icon2} className='h-[40px] w-[40px] mb-4'></img>
      <h2 className='font-[700] font-plus-jakarta-sans text-[24px] leading-[32px]'>Schedule Service</h2>
      <p className='text-[18px] font-plus-jakarta-sans leading-[28px] font-[400]' >Book a free technician visit at your<br></br>
       home or a remote meeting at a time<br></br>
       <p className=' max-w-[300px] lg:max-w-[400px]'>slot that best suits your
       convenience. </p>
      </p>
    </div>
    <div className='flex flex-col gap-[10px]'>
      <img src={icon3} className='h-[40px] w-[40px] mb-4'></img>
      <h2 className='font-[700] font-plus-jakarta-sans text-[24px] leading-[32px]'>Get Gadget Repaired</h2>
      <p className='text-[18px] font-plus-jakarta-sans leading-[28px] font-[400]' >Our super-skilled technician will be<br></br>
       there and make it as good as new.
      </p>
    </div>
    </div>
  </div>
  )
}

export default FeaturesRepair