import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Issue from "../category/Issue";
import MobileNav from "./MobileNav";
import ScreenNav from "./ScreenNav";
import Comments from "../category/Comments";
import BookingConfirmed from './BookingConfirmed';
import YourDevice from "../category/YourDevice";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Title from "antd/es/skeleton/Title";

const Integration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [repairBookingDetailsFinalPage, setRepairBookingDetailsFinalPage] = useState(null);
  const [addressResponse, setAddressResponse] = useState(null);
  const [defectDetails, setDefectDetails] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const bookingId = new URLSearchParams(window.location.search).get("booking_id");
    if (!bookingId) {
      setIsError(true);
      return;
    }

    const fetchBookingDetails = async () => {
      const authToken = sessionStorage.getItem("authToken");
      const options = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}:9009/payment-service/api/payment/details-repair`,
        params: { bookingId },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const { data } = await axios.request(options);
        console.log(data);
        setRepairBookingDetailsFinalPage(data.repairBookingDetailsFinalPage);
        setAddressResponse(data.addressResponse);
      } catch (error) {
        console.error(error);
        setIsError(true); // Set error state to true if the request fails
      }
    };

    fetchBookingDetails();
  }, []);

  useEffect(() => {
    const fetchDefectDetails = async () => {
      const options = {
        method: 'GET',
        url: `https://apiv1.gadgetsreborn.com:8070/master-service/defect/${repairBookingDetailsFinalPage?.defectSkuCode}`,
        headers: { accept: '*/*' }
      };

      try {
        const { data } = await axios.request(options);
        setDefectDetails(data);
      } catch (error) {
        console.error(error);
        setIsError(true); // Set error state if the request fails
      }
    };

    if (repairBookingDetailsFinalPage?.defectSkuCode) {
      fetchDefectDetails();
    }
  }, [repairBookingDetailsFinalPage?.defectSkuCode]);

  // Data to pass into Issue component
  const Title1 = repairBookingDetailsFinalPage?.brandName;
  const Brand = repairBookingDetailsFinalPage?.brandName;
  const Model = repairBookingDetailsFinalPage?.model;
  const Type = repairBookingDetailsFinalPage?.categoryName;
  const Title2 = defectDetails?.name || "Issue Title";
  const Description2 = defectDetails?.description || "Issue Description";
  const Image = defectDetails?.imageUrl;

 console.log(Title1, Brand, Model, Title2, Description2, Image);

  const handleChange = () => {
    navigate("/repair/defects", { state: { ...location.state } });
  };

  const handlePhoneChange = () => {
    navigate("/repair/category", { state: { ...location.state } });
  };


  return (
    <div className="bg-grSilver">
      <Navbar />
      <div className="sm:pr-[36px] px-[24px] items-center justify-center gap-[50px] flex flex-col pb-[90px] mt-8 sm:mt-[60px]">
        <div className="flex lg:flex-row flex-col xl:gap-[300px]" style={{overflowX:'hidden'}}>
          <div className="lg:hidden block self-center">
            <MobileNav />
          </div>
          <div className="flex-grow lg:max-w-auto pl-2 mr-6">
            <BookingConfirmed />
          </div>
          <div className="flex lg:justify-center justify-start lg:border-l-[2px] border-t-[2px] lg:border-t-[0px] mt-4 ">
            <div className="flex flex-col gap-[30px] lg:ml-5 ">
              <div className="hidden lg:block">
                <ScreenNav />
              </div>
              {/* <div className="flex flex-col gap-[10px] border-b-[1px] sm:h-[140px] h-[160px] mb-24 ml-3" style={{maxWidth:'90%'}}>
                <div className="flex flex-row justify-between sm:ml-12 lg:ml-0 mt-4">
                  <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                    Your Device
                  </p>
                </div>
                <YourDevice
                  phone={Title1}
                  type={Type}
                  Brand={Brand}
                  Model={Model}
                />
                <div className="flex flex-row justify-between sm:mt-3 mt-0 sm:pt-0">
                  <p className="text-[#475467] font-[700] text-[14px] leading-[20px] font-grFont self-start">
                    Your Issue
                  </p>

                </div>
                <Issue
                  title={Title2}
                  description={Description2}
                  icon={Image}
                />
              </div> */}
              <div className="mt-4">
                <Comments />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
